import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/layout'

export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login'),
    hidden: true,
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/403'),
    hidden: true,
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    hidden: true,
  },
  {
    path: '/iframeDashboard',
    name: 'iframeDashboard',
    component: () => import('@/views/iframeDashboard'),
    hidden: true,
  },
  {
    path: '/iframeDashboard/list',
    name: 'iframeDashboardList',
    component: () => import('@/views/iframeDashboard/list'),
    hidden: true,
  },
  {
    path: '/verify-code', // https://staging.www.ulifecloud.com/#/verify-code?stationId=yyyyyy
    name: 'verifyCode',
    component: () => import('@/views/verifyCode/main'),
    hidden: true,
  },
  {
    path: '/verify-code/inputPhoneNumber',
    name: 'verifyCodeInputPhoneNumber',
    component: () => import('@/views/verifyCode/inputPhoneNumber'),
    hidden: true,
  },
  {
    path: '/verify-code/qrCode',
    name: 'verifyCodeQrCode',
    component: () => import('@/views/verifyCode/qrCode'),
    hidden: true,
  },
]
export const asyncRoutes = [
  {
    webConfigId: 'dashboard',
    webConfigIsEnable: true,
    hidden: false,
    path: '/',
    component: Layout,
    redirect: '/index',
    meta: {
      title: 'LeftMenu.dashboard',
      icon: 'layout-grid-line',
      affix: true,
    },
    children: [
      {
        path: 'index',
        name: 'Index',
        component: () => import('@/views/index'),
        meta: {
          title: 'LeftMenu.dashboard',
          icon: 'layout-grid-line',
          affix: true,
        },
      },
      {
        path: 'villageInfo',
        name: 'VillageInfo',
        component: () => import('@/views/villageInfo'),
        meta: {
          icon: 'layout-grid-line',
        },
        hidden: true,
      },
    ],
  },
  {
    webConfigId: 'health_data',
    webConfigIsEnable: false,
    hidden: true,
    path: '/measurements',
    component: Layout,
    redirect: '/measurements/all',
    meta: {
      title: 'LeftMenu.measurements',
      icon: 'file-list-2-line',
      //keepAlive: true, // TODO(Dio) this parameter does not work, use keep-alive include and Componment name
    },
    children: [
      {
        path: 'all',
        name: 'MeasurementsAll',
        component: () => import('@/views/measurements'),
        meta: {
          title: 'LeftMenu.measurements',
          icon: 'file-list-2-line',
          //keepAlive: true, // TODO(Dio) this parameter does not work, use keep-alive include and Componment name
        },
      },
      {
        path: 'period/:fid',
        name: 'MeasurementsPeriod',
        component: () => import('@/views/measurements/casePeriod'),
        meta: {
          //title: '個案期間量測',
          icon: 'file-list-2-line',
        },
        hidden: true,
      },
    ],
  },
  {
    webConfigId: 'subject_management',
    webConfigIsEnable: false,
    hidden: true,
    path: '/caseManagement',
    component: Layout,
    redirect: '/caseManagement/all',
    meta: {
      title: 'LeftMenu.caseManagement',
      icon: 'file-3-line',
    },
    children: [
      {
        path: 'all',
        name: 'CaseManagementAll',
        component: () => import('@/views/case'),
        meta: {
          title: 'LeftMenu.caseManagement',
          icon: 'file-3-line',
        },
      },
      {
        path: 'groupManagement',
        name: 'GroupManagement',
        component: () => import('@/views/case/groupManagement'),
        meta: {
          //title: '群組管理',
          icon: 'file-3-line',
          //keepAlive: false,
        },
        hidden: true,
      },
    ],
  },
  {
    webConfigId: 'serial_number',
    webConfigIsEnable: false,
    hidden: true,
    path: '/serialNumber',
    component: Layout,
    redirect: '/serialNumber/all',
    meta: {
      title: 'LeftMenu.serialNumber',
      icon: 'computer-line',
    },
    children: [
      {
        path: 'all',
        name: 'SerialNumberAll',
        component: () => import('@/views/serialNumber'),
        meta: {
          title: 'LeftMenu.serialNumber',
          icon: 'computer-line',
        },
      },
    ],
  },
  {
    webConfigId: 'settings',
    webConfigIsEnable: false,
    hidden: true,
    path: '/meaureThreshold',
    component: Layout,
    redirect: '/meaureThreshold/all',
    meta: {
      title: 'LeftMenu.meaureThreshold',
      icon: 'tools-fill',
    },
    children: [
      {
        path: 'all',
        name: 'MeaureThresholdAll',
        component: () => import('@/views/meaureThreshold'),
        meta: {
          title: 'LeftMenu.meaureThreshold',
          icon: 'tools-fill',
        },
      },
    ],
  },
  {
    webConfigId: 'health_station_setting',
    webConfigIsEnable: false,
    hidden: true,
    path: '/healthStationSetting',
    component: Layout,
    redirect: '/healthStationSetting/all',
    meta: {
      title: 'LeftMenu.healthStationSetting',
      icon: 'computer-line',
    },
    children: [
      {
        path: 'all',
        name: 'HealthStationSettingAll',
        component: () => import('@/views/healthStationSetting'),
        meta: {
          title: 'LeftMenu.healthStationSetting',
          icon: 'computer-line',
        },
      },
    ],
  },
  // {
  //   path: '/vab',
  //   component: Layout,
  //   redirect: '/vab/table',
  //   alwaysShow: true,
  //   meta: {
  //     title: '组件',
  //     icon: 'apps-line',
  //   },
  //   children: [
  //     {
  //       path: 'table',
  //       name: 'Table',
  //       component: () => import('@/views/vab/table'),
  //       meta: {
  //         title: '表格',
  //         icon: 'table-2',
  //       },
  //     },
  //     {
  //       path: 'icon',
  //       name: 'Icon',
  //       component: () => import('@/views/vab/icon'),
  //       meta: {
  //         title: '图标',
  //         icon: 'remixicon-line',
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: '/test',
  //   component: Layout,
  //   redirect: '/test/test',
  //   meta: {
  //     title: '动态路由测试',
  //     icon: 'test-tube-line',
  //   },
  //   children: [
  //     {
  //       path: 'test',
  //       name: 'Test',
  //       component: () => import('@/views/test'),
  //       meta: {
  //         title: '动态路由测试',
  //         icon: 'test-tube-line',
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: '/error',
  //   name: 'Error',
  //   component: Layout,
  //   redirect: '/error/403',
  //   meta: {
  //     title: '错误页',
  //     icon: 'error-warning-line',
  //   },
  //   children: [
  //     {
  //       path: '403',
  //       name: 'Error403',
  //       component: () => import('@/views/403'),
  //       meta: {
  //         title: '403',
  //         icon: 'error-warning-line',
  //       },
  //     },
  //     {
  //       path: '404',
  //       name: 'Error404',
  //       component: () => import('@/views/404'),
  //       meta: {
  //         title: '404',
  //         icon: 'error-warning-line',
  //       },
  //     },
  //   ],
  // },
  {
    path: '/*',
    redirect: '/404',
    hidden: true,
  },
]
const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes,
})

export default router
