import request from '@/utils/request'

export async function getLicenses() {
  //console.log(params)
  return request({
    url: '/licenses',
    method: 'get',
  })
}

export async function getAppAlias() {
  //console.log(params)
  return request({
    url: '/app.alias',
    method: 'get',
  })
}
