import { render } from "./index.vue?vue&type=template&id=6176ae3a"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"

import "./index.vue?vue&type=style&index=0&id=6176ae3a&lang=less"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "6176ae3a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6176ae3a', script)) {
    api.reload('6176ae3a', script)
  }
  
  module.hot.accept("./index.vue?vue&type=template&id=6176ae3a", () => {
    api.rerender('6176ae3a', render)
  })

}

script.__file = "src/layout/vab-content/index.vue"

export default script