import { render } from "./index.vue?vue&type=template&id=6d94c42e&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"

import "./index.vue?vue&type=style&index=0&id=6d94c42e&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-6d94c42e"
/* hot reload */
if (module.hot) {
  script.__hmrId = "6d94c42e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6d94c42e', script)) {
    api.reload('6d94c42e', script)
  }
  
  module.hot.accept("./index.vue?vue&type=template&id=6d94c42e&scoped=true", () => {
    api.rerender('6d94c42e', render)
  })

}

script.__file = "src/layout/vab-icon/index.vue"

export default script