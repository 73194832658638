import { render } from "./MenuItem.vue?vue&type=template&id=38a38ecd"
import script from "./MenuItem.vue?vue&type=script&lang=js"
export * from "./MenuItem.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "38a38ecd"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('38a38ecd', script)) {
    api.reload('38a38ecd', script)
  }
  
  module.hot.accept("./MenuItem.vue?vue&type=template&id=38a38ecd", () => {
    api.rerender('38a38ecd', render)
  })

}

script.__file = "src/layout/vab-menu/components/MenuItem.vue"

export default script