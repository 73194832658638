import { getStationInfo, getOtp, verifyOtp } from '@/api/otp'

const state = () => ({
  organizationId: '',
  organizationName: '',
  phoneNumber: '',
  epass: '',
})

const getters = {
  organizationId: (state) => state.organizationId,
  organizationName: (state) => state.organizationName,
  phoneNumber: (state) => state.phoneNumber,
  epass: (state) => state.epass,
}

const mutations = {
  setOrganizationId(state, organizationId) {
    state.organizationId = organizationId
  },
  setOrganizationName(state, organizationName) {
    state.organizationName = organizationName
  },
  setPhoneNumber(state, phoneNumber) {
    state.phoneNumber = phoneNumber
  },
  setEpass(state, epass) {
    state.epass = epass
  },
}

const actions = {
  async getStationInfo({ commit }, stationId) {
    try {
      console.log('stationId', stationId)
      const data = await getStationInfo(stationId)
      console.log(data)
      if (data?.data?.ok === true && data?.data?.result) {
        commit('setOrganizationId', data?.data?.result.oid)
        commit('setOrganizationName', data?.data?.result.title)
      } else {
        commit('setOrganizationId', '')
      }
    } catch (e) {
      commit('setOrganizationId', '')
      console.log('exception')
      console.log(e)
    }
  },
  async getOtp({ commit }, postData) {
    try {
      const data = await getOtp(postData)
      if (data?.data?.ok === true) {
        commit('setPhoneNumber', postData.phoneNumber ?? '')
        return data?.data
      } else {
        // commit('setPhoneNumber', postData.phoneNumber ?? '')
        commit('setPhoneNumber', '')
        return data?.data
      }
    } catch (e) {
      // commit('setPhoneNumber', postData.phoneNumber ?? '')
      commit('setPhoneNumber', '')
      console.log('exception')
      console.log(e)
      return e?.response?.data
    }
  },
  async verifyOtp({ commit }, postData) {
    try {
      const data = await verifyOtp(postData)
      if (data?.data?.ok === true && data?.data?.ePass) {
        commit('setEpass', data?.data?.ePass)
        return true
      } else {
        return false
      }
    } catch (e) {
      // commit(
      //   'setEpass',
      //   '6aas5L6G6KW/5Lqe5YGl5bq36KGXOjptYWxheXNpYS1oZWFsdGhzdHJlZXQub3JnOjpDaW5hIENoaXU6OjZmZWJlODQwLWNlNDctNGRlMy04YWRiLTcxNDYwZjJmN2U3NTo6MTcyNjAzMzEyOC4yNw=='
      // ) // TODO(Dio), for testing
      console.log('exception')
      console.log(e)
      return false
    }
  },
}

export default { state, getters, mutations, actions }
