import { getLicenses, getAppAlias } from '@/api/serialNumber'

const state = () => ({
  serialNumber: [],
  appPlan: [],
})

const getters = {
  serialNumber: (state) => state.serialNumber,
  appPlan: (state) => state.appPlan,
}

const mutations = {
  setSerialNumber(state, serialNumber) {
    state.serialNumber = serialNumber
  },
  setAppPlan(state, appPlan) {
    state.appPlan = appPlan
  },
}

const actions = {
  async getSetSerialNumber({ commit }) {
    try {
      const data = await getLicenses()
      if (data?.ok === true && data?.result) {
        commit('setSerialNumber', data?.result)
      } else {
        commit('setOrganizationId', [])
      }
    } catch (e) {
      commit('setOrganizationId', [])
      console.log('exception')
      console.log(e)
    }
  },
  async getAppPlan({ commit }) {
    try {
      const data = await getAppAlias()
      if (data?.ok === true) {
        commit('setAppPlan', data?.result ?? [])
        return data
      } else {
        commit('setAppPlan', [])
        return data
      }
    } catch (e) {
      commit('setAppPlan', [])
      console.log('exception')
      console.log(e)
      return e?.response?.data
    }
  },
}

export default { state, getters, mutations, actions }
