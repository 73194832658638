/**
 * @author noname
 * @description 登录、获取用户信息、退出登录、清除accessToken逻辑，不建议修改
 */
import { login, getWebConfig, getHasHealthStations } from '@/api/user'
import { getStationMethod } from '@/api/station'
import { getLicenses } from '@/api/serialNumber'
import { getLocalStorage, setLocalStorage } from '@/utils/localStorage'

import {
  getAccessToken,
  removeAccessToken,
  setAccessToken,
} from '@/utils/accessToken'
//import { title } from '@/config'
import { message } from 'ant-design-vue'

const state = () => ({
  accessToken: getAccessToken(),
  account: getLocalStorage('account') || '',
  avatar: '',
  webConfig: getLocalStorage('webConfig') || [],
  title: getLocalStorage('title') || '',
  role: getLocalStorage('role') || '',
  expiredTs: getLocalStorage('accountExpiredTs') || 0,
  activationTs: getLocalStorage('accountActivationTs') || 0,
})
const getters = {
  accessToken: (state) => state.accessToken,
  account: (state) => state.account,
  avatar: (state) => state.avatar,
  webConfig: (state) => state.webConfig,
  title: (state) => state.title,
  role: (state) => state.role,
  expiredTs: (state) => state.expiredTs,
  activationTs: (state) => state.activationTs,
}
const mutations = {
  setWebConfig(state, webConfig) {
    state.webConfig = webConfig
    setLocalStorage('webConfig', state.webConfig)
  },
  /**
   * @author noname
   * @description 设置accessToken
   * @param {*} state
   * @param {*} accessToken
   */
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken
    setAccessToken(accessToken)
  },
  /**
   * @author noname
   * @description 设置用户名
   * @param {*} state
   * @param {*} account
   */
  setAccount(state, account) {
    state.account = account
    setLocalStorage('account', state.account)
  },
  /**
   * @author noname
   * @description 设置头像
   * @param {*} state
   * @param {*} avatar
   */
  setAvatar(state, avatar) {
    state.avatar = avatar
  },

  setTitle(state, title) {
    state.title = title
    setLocalStorage('title', state.title)
  },

  setRole(state, role) {
    state.role = role
    setLocalStorage('role', state.role)
  },

  setExpiredTs(state, expiredTs) {
    state.expiredTs = expiredTs
    setLocalStorage('accountExpiredTs', state.expiredTs)
  },

  setActivationTs(state, activationTs) {
    state.activationTs = activationTs
    setLocalStorage('accountActivationTs', state.activationTs)
  },
}
const actions = {
  async getWebConfig({ commit }, account) {
    const data = await getWebConfig(account)
    const StationMethod = await getStationMethod()
    // 健康小站設定用不同的 api 取得，但是 menu 的 show/hide 統一用 web.configurations 回傳的格式，所以補一個健康小站設定的 menu
    if (StationMethod?.ok === true && StationMethod?.methods?.E_PASS === true) {
      data.result.push({
        id: 'health_station_setting',
        title: {
          en: 'Health Station Setting',
          tw: '健康小站設定',
        },
        privilege: {
          show: true,
          enable: true,
        },
      })
    }
    const licenses = await getLicenses()
    if (licenses?.ok === true && licenses?.result?.length > 0) {
      data.result.push({
        id: 'serial_number',
        title: {
          en: 'Serial Number',
          tw: '序號',
        },
        privilege: {
          show: true,
          enable: true,
        },
      })
    }
    // const HSdata = await getHasHealthStations()
    // // 健康小站設定用不同的 api 取得，但是 menu 的 show/hide 統一用 web.configurations 回傳的格式，所以補一個健康小站設定的 menu
    // if (HSdata?.value === true) {
    //   data.result.push({
    //     id: 'health_station_setting',
    //     title: {
    //       en: 'Health Station Setting',
    //       tw: '健康小站設定',
    //     },
    //     privilege: {
    //       show: true,
    //       enable: true,
    //     },
    //   })
    // }
    commit('setWebConfig', data)
  },
  /**
   * @author noname
   * @description 登录拦截放行时，设置虚拟角色
   * @param {*} { commit, dispatch }
   */
  setVirtualRoles({ commit, dispatch }) {
    dispatch('acl/setFull', true, { root: true })
    commit('setAvatar', 'https://i.gtimg.cn/club/item/face/img/2/15922_100.gif')
    commit('setAccount', 'admin(未開登入攔截)')
  },
  /**
   * @author noname
   * @description 登录
   * @param {*} { commit }
   * @param {*} userInfo
   */
  async login({ commit }, { userInfo, errorMessage }) {
    const data = await login(userInfo)
    const accessToken = data['oauth_token']
    const expiredTs = data['expiration_date'] || 0 // 毫秒
    const activationTs = data['activation_date'] || 0 // 毫秒
    const title = data.title || ''
    const role = data.role || '' // dealer, client
    commit('setExpiredTs', expiredTs)
    commit('setActivationTs', activationTs)
    if (accessToken) {
      commit('setAccessToken', accessToken)
      // const hour = new Date().getHours()
      // const thisTime =
      //   hour < 8
      //     ? '早上好'
      //     : hour <= 11
      //     ? '上午好'
      //     : hour <= 13
      //     ? '中午好'
      //     : hour < 18
      //     ? '下午好'
      //     : '晚上好'
      const account = userInfo && userInfo.account ? userInfo.account : ''
      commit('setAccount', account)
      commit('setTitle', title)
      commit('setRole', role)
      commit('routes/changeRole', role, {
        root: true,
      })
      // notification.open({
      //   message: `歡迎登入${title}`,
      //   description: `${thisTime}！`,
      //   //description: t('LI001.loginFail'),
      // })

      const webConfig = await getWebConfig(userInfo.account)

      const StationMethod = await getStationMethod()
      // 健康小站設定用不同的 api 取得，但是 menu 的 show/hide 統一用 web.configurations 回傳的格式，所以補一個健康小站設定的 menu
      if (
        StationMethod?.ok === true &&
        StationMethod?.methods?.E_PASS === true
      ) {
        webConfig.result.push({
          id: 'health_station_setting',
          title: {
            en: 'Health Station Setting',
            tw: '健康小站設定',
          },
          privilege: {
            show: true,
            enable: true,
          },
        })
      }
      const licenses = await getLicenses()
      if (licenses?.ok === true && licenses?.result?.length > 0) {
        webConfig.result.push({
          id: 'serial_number',
          title: {
            en: 'Serial Number',
            tw: '序號',
          },
          privilege: {
            show: true,
            enable: true,
          },
        })
      }

      // const HSdata = await getHasHealthStations()
      // // 健康小站設定用不同的 api 取得，但是 menu 的 show/hide 統一用 web.configurations 回傳的格式，所以補一個健康小站設定的 menu
      // if (HSdata?.value === true) {
      //   webConfig.result.push({
      //     id: 'health_station_setting',
      //     title: {
      //       en: 'Health Station Setting',
      //       tw: '健康小站設定',
      //     },
      //     privilege: {
      //       show: true,
      //       enable: true,
      //     },
      //   })
      // }
      if (webConfig && webConfig.result) {
        commit('setWebConfig', webConfig.result)
        commit('routes/changeRouteByWebConfig', webConfig.result, {
          root: true,
        })
      }
    } else {
      message.error(errorMessage)
    }
  },
  /**
   * @author noname
   * @description 获取用户信息接口 这个接口非常非常重要，如果没有明确底层前逻辑禁止修改此方法，错误的修改可能造成整个框架无法正常使用
   * @param {*} { commit, dispatch, state }
   * @returns
   */
  // async getUserInfo({ commit, dispatch, state }) {
  //   const data = await getUserInfo(state.accessToken)
  //   if (!data) {
  //     message.error(`驗證失敗，請重新登入...`)
  //     return false
  //   }
  //   let { username, avatar, roles, ability } = data
  //   if (username && roles && Array.isArray(roles)) {
  //     dispatch('acl/setRole', roles, { root: true })
  //     if (ability && ability.length > 0)
  //       dispatch('acl/setAbility', ability, { root: true })
  //     commit('setUsername', username)
  //     commit('setAvatar', avatar)
  //   } else {
  //     message.error('無法取得用戶資訊')
  //   }
  // },

  // Default set admin role
  // the behavior not match business, does not use origianl role base access controll mechanism
  async getUserInfo({ dispatch, state }) {
    if (!state.accessToken) {
      message.error('Authorization Failed')
      return false
    }
    let roles = ['admin']
    let ability = ['READ', 'WRITE', 'DELETE']

    dispatch('acl/setRole', roles, { root: true })
    dispatch('acl/setAbility', ability, { root: true })
  },

  /**
   * @author noname
   * @description 退出登录
   * @param {*} { dispatch }
   */
  async logout({ dispatch }) {
    //await logout(state.accessToken)
    setLocalStorage('hasShowAccountWarning', 0)
    await dispatch('resetAll')
  },
  /**
   * @author noname
   * @description 重置accessToken、roles、ability、router等
   * @param {*} { commit, dispatch }
   */
  async resetAll({ dispatch }) {
    await dispatch('setAccessToken', '')
    await dispatch('acl/setFull', false, { root: true })
    await dispatch('acl/setRole', [], { root: true })
    await dispatch('acl/setAbility', [], { root: true })
    removeAccessToken()
  },
  /**
   * @author noname
   * @description 设置token
   */
  setAccessToken({ commit }, accessToken) {
    commit('setAccessToken', accessToken)
  },
}
export default { state, getters, mutations, actions }
